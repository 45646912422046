import React from "react";
// import PropTypes from 'prop-types';
import "../src/fonts/HandjetCircleDoubleMedium.ttf";

const NotFound = (props) => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        fontWeight: "400",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#00d322",
        fontFamily: "Medium",
        fontSize: "52px",
      }}
    >
      <p>Page Not Found :(</p>
    </div>
  );
};

NotFound.propTypes = {};

export default NotFound;
