import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogin: false,
  authToken: "",
  isLoading: false,
};

export const commonSlice = createSlice({
  name: "trgBot",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.isLogin = action.payload.isLogin;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
  },
});

export const { setLogin, setAuthToken, setLoading } = commonSlice.actions;
export default commonSlice.reducer;
