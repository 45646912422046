import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { botApi } from "../Services/Bot";
import { loginApi } from "../Services/Login";
import rootReducer from "./trgBotSlice";
export const store = configureStore({
  reducer: {
    commonRed: rootReducer,
    // Add the generated reducer as a specific top-level slice
    [loginApi.reducerPath]: loginApi.reducer,
    [botApi.reducerPath]: botApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(loginApi.middleware)
      .concat(botApi.middleware),
});

setupListeners(store.dispatch);

export const select = (state) => state.commonRed;
