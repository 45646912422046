import "./App.css";
import "antd/dist/reset.css";
import { AppRoutes } from "./Routes/AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./fonts/HandjetCircleDoubleMedium.ttf";
function App() {
  return (
    <div className="App">
      <AppRoutes />
      <ToastContainer
        toastStyle={{
          backgroundColor: "black",
          color: "#00d322",
          fontFamily: "Medium",
          fontSize:"24px"
        }}
        hideProgressBar={true}
      />
    </div>
  );
}

export default App;
