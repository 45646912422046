import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ROUTES } from "../Constants/Routes";
import NotFound from "../NotFound";
export const AppRoutes = () => {
  const Home = lazy(() => import("../Screens/Home/Home"));
  const HomeMain = lazy(() => import("../Screens/Home/Main/Main"));
  const Bot = lazy(() => import("../Screens/Bots/Bots"));
  const BotMain = lazy(() => import("../Screens/Bots/Main/Main"));

  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div className="loader"></div>
        </div>
      }
    >
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />}>
          <Route index element={<HomeMain />} />
        </Route>
        <Route path={ROUTES.BOT} element={<Bot />}>
          <Route index element={<BotMain />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};
