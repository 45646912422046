import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { toast } from "react-toastify";
import { AuthToken, baseUrl } from "./config";

export const rtkBaseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers) => {
    if (localStorage.getItem("AuthToken")) {
      headers.set(
        "authorization",
        `Bearer ${localStorage.getItem("AuthToken")}`
      );
    }
    return headers;
  },
});

export const subString = (str) => {
  const string = str?.slice(0, 4) + "..." + str?.slice(str?.length - 3);
  return string;
};

export const copyFunction = (val) => {
  if (val) {
    navigator.clipboard.writeText(val);
    toast("Address copied successfully");
  } else {
    toast("Address not found");
  }
};
